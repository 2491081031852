.logo {
    height: $header-height;
    display: flex;
    align-items: center;
    padding: 0 2rem;
    background-color: transparent;
    transition: all .2s ease;
}
.logo-sm {
    height: $header-height;
    display: flex;
    align-items: center;
    padding: 0 1rem;
    background-color: transparent;
    transition: all .2s ease;
}